import {qboCallback} from "../../services/common"
import {useEffect, useState} from "react"
import {Spinner} from "@blueprintjs/core"
// import {useNavigate} from "react-router-dom"

const QboCallback = () =>  {
  const location = window.location.href
  const [loading, setLoading] = useState(true)
  const [msg, setMsg] = useState('')
  useEffect(() => {
    (async () => {
      try {
        // const response = await qboCallback(location)
        await qboCallback(location)
        setLoading(false)
        setMsg('Connection request is sent successfully.')
      } catch(e) {
        setLoading(false)
        setMsg('There was a problem connecting. Please contact the administrator.')
      }
      // navigate('/qbo')
    })()
  }, [location])

  return (
    <div>
      {loading && <Spinner/>}
      {msg && <h5>{msg}</h5>}
    </div>
  );
}

export default QboCallback;