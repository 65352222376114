import {Button} from "@blueprintjs/core"
import {qboLogin} from "../../services/common"

const QboHome = () =>  {

  const handleQboLogin = async () => {
    const response = await qboLogin();
    if(response.status === 200) {
      if(response?.data?.redirectUri) {
        window.location.href = response?.data?.redirectUri
      }
    }
  }
  return (
    <div>
      <h2>Connect to your QBO account</h2>
      <Button text="Connect to QBO" onClick={handleQboLogin}/>
    </div>
  );
}

export default QboHome;