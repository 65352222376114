import {useState} from "react"
import LoginForm from "../../components/LoginForm"


const MainLogin = ({onSubmit, isError}) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");



  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6 offset-md-3 mt-5">
          <div>
            <LoginForm
              username={username}
              onChangeUsername={(e) => setUsername(e.target.value)}
              onChangePassword={(e) => setPassword(e.target.value)}
              password={password}
              onSubmit={() => onSubmit(username, password)}
            />
          </div>
        </div>
      </div>
      {isError && <p>Invalid username or password</p>}
    </div>
  );
}

export default MainLogin