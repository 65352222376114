import {Routes, Route, Outlet, Link, useNavigate} from "react-router-dom";
import QboHome from "./pages/qbo/qboHome"
import {useEffect, useState} from "react"
import './App.css'
import {Card, Elevation, Spinner} from "@blueprintjs/core"
import MainLogin from "./pages/MainLogin/MainLogin"
import backgroundImage2 from './images/illustration.png';
import terexLogo from './images/bannerlogo.png';
import {basicLogin, checkBasicLogin} from "./services/common"
import axios from 'axios'
import QboCallback from "./pages/QboCallback/QboCallback"


export default function App() {
  const [isLogin, setIsLogin] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const handleLogin = async (username, password) => {
    setIsLoading(true)
    const response = await basicLogin(username, password);
    if (response.status === 200) {
      const {userid, token} = response.data
      sessionStorage.setItem('userid', userid)
      sessionStorage.setItem('token', token)
      axios.defaults.headers.common['userid'] = userid;
      axios.defaults.headers.common['token'] = token;
      setIsLogin(true);
      setIsError(false);
      setIsLoading(false)
      navigate('/qbo')
    } else {
      setIsError(true);
      setIsLogin(false);
      setIsLoading(false)
    }
  };
  useEffect(() => {
    (async () => {
      const userIdFromSession = sessionStorage.getItem('userid')
      const tokenFromSession = sessionStorage.getItem('token')
      axios.defaults.headers.common['userid'] = userIdFromSession
      axios.defaults.headers.common['token'] = tokenFromSession
      setIsLoading(true)
      const response = await checkBasicLogin();
      setIsLoading(false)
      if(!response) {
        setIsLogin(false);
      } else {
        setIsLogin(true);
      }
    })()
  }, [])
  useEffect(() => {
    if(!isLoading && !isLogin) {
      navigate('/')
    }
  }, [isLoading, isLogin])

  /* add class for dark mode  bp5-dark */
  return (
    <div className={'App'}>
      <div className="backgroundContainer">
        <div className="background-image-holder" role="presentation" >
          <div id="backgroundImage" role="img" className="background-image ext-background-image" aria-label="Organisation background image" style={{backgroundImage: "url(" + backgroundImage2 + ")"}}></div>
          <div className="background-overlay ext-background-overlay"></div>
        </div>
      </div>
      <Routes>
        <Route path="/" element={<Layout isLoading={isLoading}/>}>
          <Route index element={<MainLogin onSubmit={handleLogin} error={isError}/>} />
          <Route path="qbo" element={<QboHome />} />
          <Route path="qbocallback" element={<QboCallback />} />
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </div>
  );
}

function Layout({isLoading}) {
  return (
    <div className={'layout-container'}>
      <Card elevation={Elevation.TWO}>
        <img src={terexLogo} className={'terexLogo'} alt="Terex Logo" width={'70%'}/>
        <h1>Terex CDI Connect</h1>
        <p>
          This application is used to facilitate integration between Terex and it's dealers systems.
        </p>
        <hr />
        {
          isLoading ? <Spinner /> : <Outlet />
        }
      </Card>
    </div>
  );
}


function NoMatch() {
  return (
    <div>
      <h2>This page was not found. Please contact the administrator who provided you the link. </h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}