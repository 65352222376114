import axios from 'axios'

export const basicLogin = async (username, password) => {
  return await axios.post(`${process.env.REACT_APP_API_BASE_URL}/login`, {
    username,
    password,
  })
}


export const qboLogin = async () => {
    return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/qbologin`)
}

export const checkBasicLogin = async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/checklogin`)
  return response?.data?.loggedIn
}
export const qboCallback = async (qboCallbackUrl) => {
  const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/callback`, {
    qboCallbackUrl: qboCallbackUrl
  })
  return response
}
