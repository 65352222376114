import React from 'react';
import { InputGroup, Button } from "@blueprintjs/core";
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';

const LoginForm = ({username, password, onSubmit, onChangePassword, onChangeUsername}) => {
  return (
    <div style={{ maxWidth: "300px", margin: "50px auto" }}>
      <h2 className="bp3-heading">Login</h2>
      <InputGroup
        id="username"
        placeholder="Username"
        leftIcon="user"
        large={true}
        value={username}
        onChange={onChangeUsername}
      />
      <br />
      <InputGroup
        id="password"
        placeholder="Password"
        leftIcon="lock"
        type="password"
        large={true}
        value={password}
        onChange={onChangePassword}
      />
      <br />
      <Button type="submit" text="Submit" large={true} intent="primary" onClick={onSubmit}/>
    </div>
  );
};

export default LoginForm;
